import React, { useRef, useEffect, useCallback } from "react";
import { Rect as KonvaRectangle, Transformer } from "react-konva";

import { LIMITS } from "./constants";
import { selectShape, transformRectangleShape, moveShape, useShapes } from "./state";

const boundBoxCallbackForRectangle = (oldBox, newBox) => {
  // limit resize
  if (
    newBox.width < LIMITS.RECT.MIN ||
    newBox.height < LIMITS.RECT.MIN ||
    newBox.width > LIMITS.RECT.MAX ||
    newBox.height > LIMITS.RECT.MAX
  ) {
    return oldBox;
  }
  return newBox;
};

export function Rectangle({ id, isSelected, type, handleRectSelect, imageWidth, imageHeight, handleMove, ...shapeProps }) {
  const shapeRef = useRef();
  const transformerRef = useRef();
  const shapes = useShapes((state) => Object.entries(state.shapes));


  useEffect(() => {
    if (isSelected) {
      transformerRef.current.nodes([shapeRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  const handleSelect = useCallback(
    (event) => {
      event.cancelBubble = true;
      handleRectSelect(true, { id, ...shapeProps });
      selectShape(id);
    },
    [id, shapeProps]
  );

  const handleDrag = useCallback(
    (event) => {
      moveShape(id, event);
    },
    [id]
  );

  const handleTransform = useCallback(
    (event) => {
      transformRectangleShape(shapeRef.current, id, event);
    },
    [id]
  );

  const handleDragMove = (event) => {
    const box = event.target.getClientRect();
    const absPos = event.target.getAbsolutePosition();
    const offsetX = box.x - absPos.x;
    const offsetY = box.y - absPos.y;

    const newAbsPos = { ...absPos }
    if (box.x < 0) {
      newAbsPos.x = -offsetX;
    }
    if (box.y < 0) {
      newAbsPos.y = -offsetY;
    }
    if (box.x + box.width > imageWidth) {
      newAbsPos.x = imageWidth - box.width - offsetX;
    }
    if (box.y + box.height > imageHeight) {
      newAbsPos.y = imageHeight - box.height - offsetY;
    }
    handleMove(false);
    event.target.setAbsolutePosition(newAbsPos)
  }

  let oldAttrs;
  const handleTransformer = (event) => {
    const box = event.target.getClientRect();
    const isOut = box.x < 0 || box.y < 0 || box.x + box.width > imageWidth || box.y + box.height > imageHeight;

    if (isOut) {
      event.target.setAttrs(oldAttrs);
    } else {
      oldAttrs = { ...event.target.getAttrs() };
    }
  }

  return (
    <>
      <KonvaRectangle
        onClick={handleSelect}
        onTap={handleSelect}
        onDragStart={handleSelect}
        ref={shapeRef}
        {...shapeProps}
        draggable
        onDragEnd={handleDrag}
        onTransformEnd={handleTransform}
        onDragMove={handleDragMove}
      />
      {isSelected && (
        <Transformer
          anchorSize={5}
          borderDash={[5, 2]}
          ref={transformerRef}
          // boundBoxFunc={boundBoxCallbackForRectangle}
          onTransform={handleTransformer}
        />
      )}
    </>
  );
}
