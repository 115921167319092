import React, { useRef, useCallback, useState, useEffect, useMemo } from "react";
import { Image, Layer, Rect, Stage, Text } from "react-konva";

import {
  useShapes,
  clearSelection,
  createRectangle,
  saveDiagram,
  reset,
  deleteShape,
  editShape,
} from "./state";
import { DRAG_DATA_KEY, SHAPE_TYPES } from "./constants";
import { Shape } from "./Shape";
import { getAssignedTagsAPI } from "../../Services/annotator.service";
import BasicCard from "../UI/Cards/BasicCard";
import { useNavigate } from "react-router-dom";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Popover } from "@mui/material";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import Konva from "konva";
import { useSelector } from "react-redux";

const handleDragOver = (event) => event.preventDefault();

function ColorReplaceFilter(imageData) {
  alert("yes");
  var nPixels = imageData.data.length;
  for (var i = 0; i < nPixels - 4; i += 4) {
    const isTransparent = imageData.data[i + 3] === 0;
    if (!isTransparent) {
      imageData.data[i] = 9;
      imageData.data[i + 1] = 49;
      imageData.data[i + 2] = 46;
    }
  }
};

export function Canvas({ reportImage, reportImageName, subFolderName, shapesData, accessTags, isRectangleShapeSelected,
  showImageWithoutTags, isShowLabel
}) {

  const shapes = useShapes((state) => Object.entries(state.shapes));

  const [image, setImage] = useState(null);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [endPos, setEndPos] = useState({ x: 0, y: 0 });
  const [isDrawings, setIsDrawings] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [size, setSize] = useState({});
  const [isShapeSelected, setIsShapeSelected] = useState(false);
  const [allowDraggable, setAllowDraggable] = useState(false);

  // select tags card
  const navigate = useNavigate();
  const [isShowSelectTagsCard, setIsShowSelectTagsCard] = useState(false);
  const [stepsData, setStepsData] = useState();
  const [tagIndex, setTagIndex] = useState();
  const [tagsListArray, setTagsListArray] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [permissionData, setPermissionsData] = useState();
  const [imageName, setImageName] = useState(reportImageName);
  const [selectedTagData, setSelectedTagData] = useState({});
  const [isRectangleBoxCreated, setIsRectangleBoxCreated] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedShapeData, setSelectedShapeData] = useState();
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [contrast, setContrast] = useState(1); // Default contrast
  const [brightness, setBrightness] = useState(0); // Default brightness
  const storeValue = useSelector(store => store.ui)


  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const imageRef = useRef(null);
  const stageRef = useRef();
  const mainContainerRef = useRef();

  const videoElement = useMemo(() => {
    let container = mainContainerRef.current;
    setSize({ width: container?.offsetWidth, height: container?.offsetHeight })
    const element = new window.Image();
    element.width = container?.offsetWidth;
    element.height = container?.offsetHeight;
    element.src = reportImage;

    element.onload = () => {
      imageRef?.current?.image(element);
      imageRef?.current?.cache();
      // imageRef.current.filters([Konva.Filters.Contrast]);
      // imageRef.current.getLayer().batchDraw();
      // applyFilters();
    };

    return element;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportImage, mainContainerRef?.current]);

  useEffect(() => {
    // const onload = function () {
    //   // setSize({
    //   //   width: videoElement.width,
    //   //   height: videoElement.height,
    //   // });
    //   setImage(videoElement);
    //   imageRef.current = videoElement;
    //   // imageRef.current.image(reportImage);
    //   // imageRef.current.cache();
    //   // imageRef.current.filters([Konva.Filters.Contrast]);
    //   // imageRef.current.getLayer().batchDraw();
    // };
    // videoElement.addEventListener("load", onload);
    // return () => {
    //   videoElement.removeEventListener("load", onload);
    // };
  }, [videoElement]);

  useEffect(() => {
    getTagsList();
  }, []);

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel * 1.02);
  };

  const handleZoomOut = () => {
    setZoomLevel(zoomLevel / 1.02);
  };

  const handleMouseDown = (e) => {
    if (isShapeSelected) return;
    const pos = stageRef.current.getPointerPosition();
    setStartPos({ x: pos.x / zoomLevel, y: pos.y / zoomLevel });
    setEndPos({ x: pos.x / zoomLevel, y: pos.y / zoomLevel }); // Set end position to start position initially
    setIsDrawings(isRectangleShapeSelected);
    setIsRectangleBoxCreated(true);
  };

  const handleMouseMove = (e) => {
    if (!isDrawings) return;
    const pos = stageRef.current.getPointerPosition();
    setEndPos({ x: pos.x / zoomLevel, y: pos.y / zoomLevel });
  };

  const handleMouseUp = (e) => {
    if (!isDrawings) return;
    setIsDrawings(false);
    const pos = stageRef.current.getPointerPosition();
    setEndPos({ x: pos.x / zoomLevel, y: pos.y / zoomLevel });
    // Assuming createRectangle is a function that creates a rectangle with given start and end positions
    // createRectangle(startPos, endPos, selectedTagData);
    // handleCreateRectangle(true);
    const width = pos.x / zoomLevel - startPos.x;
    const height = pos.y / zoomLevel - startPos.y;
    if (width && height) {
      setIsShowSelectTagsCard(true);
    }
  };

  const handleDraggable = () => {
    setAllowDraggable((state) => !state);
  }

  const getTagsList = async () => {
    try {
      const tagsListData = await getAssignedTagsAPI(`${subFolderName}/${imageName}`);
      if (tagsListData?.data?.data) {
        const tagsAndAccessData = tagsListData.data.data;
        setTagsList(tagsAndAccessData);
        setTagsListArray(tagsAndAccessData.access_tags);
        setPermissionsData({
          isImageUseableAccess: tagsAndAccessData.is_image_useable_access,
          isDarkTeethAccess: tagsAndAccessData.is_dark_teeth_access,
          isMissingTeethAccess: tagsAndAccessData.is_missing_teeth_access,
          isPoorAlignmentAccess: tagsAndAccessData.is_poor_alignment_access
        })
      }
    } catch (error) {
      localStorage.clear();
      navigate('/');
    }
  };

  const getSelectedTags = (tag) => {
    if (tag && !isEditEnabled) {
      setSelectedTagData(tag);
      const width = endPos.x - startPos.x;
      const height = endPos.y - startPos.y;
      if (width < 0 && height < 0) {
        // For creating reverse rectangle box
        createRectangle({ width: Math.abs(width), height: Math.abs(height), x: startPos.x - Math.abs(width), y: startPos.y - Math.abs(height) }, tag);
      } else if (width < 0) {
        createRectangle({ width: Math.abs(width), height, x: startPos.x - Math.abs(width), y: startPos.y }, tag);
      } else if (height < 0) {
        createRectangle({ width, height: Math.abs(height), x: startPos.x, y: startPos.y - Math.abs(height) }, tag);
      } else {
        createRectangle({ width, height, x: startPos.x, y: startPos.y }, tag);
      }

    } else {
      if (tag) {
        editShape(selectedShapeData, tag);
      }
      setIsEditEnabled(false);
      handleClose();
      setSelectedShapeData(false);
      clearSelection();
      setIsShapeSelected(false);
    }
    setIsShowSelectTagsCard(false);
    setIsRectangleBoxCreated(false);
    // setIsDrawings(false);
  }

  const isTagAccessed = (tag) => {
    return accessTags.find(item => {
      return item.toLowerCase() === tag.toLowerCase()
    });
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (data, shapeData) => {
    setSelectedShapeData(shapeData);
    setIsShapeSelected(data);
    setIsDrawings(false);
    console.log("select", data, shapeData);
  }

  const handleEditTag = () => {
    setIsShowSelectTagsCard(true);
    setIsEditEnabled(true);
    handleClose();
  }

  const handleDeleteTag = () => {
    deleteShape(selectedShapeData.id);
    handleClose();
    setIsShapeSelected(false);
  }

  const applyFilters = () => {
    imageRef.current.filters([
      Konva.Filters.Contrast,
      Konva.Filters.Brighten
    ]);
    imageRef.current.contrast(parseFloat(storeValue.contrast));
    imageRef.current.brightness(parseFloat(storeValue.brightness));
    imageRef.current.getLayer().batchDraw();
  };

  const handleContrastChange = (e) => {
    const { value } = e.target;
    setContrast(parseFloat(value));
  };

  const handleBrightnessChange = (e) => {
    const { value } = e.target;
    setBrightness(parseFloat(value));
  };

  useEffect(() => {
    applyFilters();
  }, [storeValue.contrast, storeValue.brightness]);

  const handleMove = (data) => {
    setIsShapeSelected(data);
  }

  return (
    <main className="w-100 position-relative" onDragOver={handleDragOver} id="mainContainer" ref={mainContainerRef}>
      <div>
        <Stage
          ref={stageRef}
          width={size.width || 800}
          height={size.height || 600}
          scaleX={zoomLevel}
          scaleY={zoomLevel}
          // onWheel={handleWheel}
          onClick={() => {
            setIsShapeSelected(false);
            clearSelection()
          }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          draggable={allowDraggable}
        >
          <Layer>
            <Image
              ref={imageRef}
              image={image}
              x={0}
              y={0}
              width={size.width}
              height={size.height}
            // scaleX={zoomLevel}
            // scaleY={zoomLevel}
            // draggable={true}
            />

            {
              !isDrawings && !showImageWithoutTags && (
                shapes.map(([key, shape]) => (
                  isTagAccessed(shape.tagType) && (
                    <React.Fragment key={key}>
                      <Shape
                        shape={{ ...shape, id: key }}
                        handleSelect={handleSelect}
                        imageWidth={size.width}
                        imageHeight={size.height}
                        handleMove={handleMove}
                      />
                      {
                        isShowLabel && (
                          <Text
                            x={shape.x - 50}
                            y={shape.y - 15} // Adjust position to place below the rectangle
                            text={shape.name}
                            fontSize={14}
                            fill={shape.stroke}
                            align="center"
                            width={100}

                          />
                        )
                      }
                    </React.Fragment>
                  )
                ))
              )
            }

            {/* Render rectangle while drawing */}
            {/* {isDrawings && (
              <>
                <Rect
                  x={startPos.x}
                  y={startPos.y}
                  width={endPos.x - startPos.x}
                  height={endPos.y - startPos.y}
                  stroke="blue"
                  strokeWidth={2}
                />
              </>
            )} */}
            {
              isRectangleBoxCreated && (
                <Rect
                  x={startPos.x}
                  y={startPos.y}
                  width={endPos.x - startPos.x}
                  height={endPos.y - startPos.y}
                  stroke={'blue'}
                  strokeWidth={2}
                />
              )
            }
          </Layer>
        </Stage>

        {
          isShowSelectTagsCard && (
            <div className='position-absolute select-tags-card'>
              <BasicCard
                onSelectedTags={getSelectedTags}
                stepsData={stepsData}
                tagIndex={tagIndex}
                tagsList={tagsListArray} />
            </div>
          )
        }

        {
          isShapeSelected && (
            <div className="position-absolute" style={{ left: `${selectedShapeData.x}px`, top: `${selectedShapeData.y}px` }}>
              <div className='d-inline-flex font-size-14 popup-header'>
                {/* <span className='text-white text-capitalize'>T</span> */}
                <span>
                  <ArrowDropDownIcon className='text-white'
                    style={{ width: '20px', height: 'auto', backgroundColor: `${selectedShapeData.color}` }}
                    onClick={handleClick}
                  />
                </span>

                {/* Edit and delete */}
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <Button
                    className='p-1 d-block font-size-13 text-capitalize cursor-pointer primary-black text-left'
                    onClick={handleEditTag}
                  >
                    <ModeEditIcon className='edit-icon' /> Edit</Button>
                  <Button
                    className='p-1 d-block font-size-13 text-capitalize cursor-pointer primary-black text-left'
                    onClick={handleDeleteTag}
                  >
                    <DeleteIcon className='edit-icon' /> Delete</Button>
                </Popover>

              </div>
            </div>
          )
        }

        {/* <div className="zoom-container position-absolute top-0">
          <button
            onClick={() => {
              handleZoomIn()
            }}
          >
            +
          </button>
          <button
            onClick={() => {
              handleZoomOut();
            }}
          >
            -
          </button>
        </div> */}
      </div>

    </main>
  );
}
