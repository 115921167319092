import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ImageAnnotationTool from "./Components/ImageAnnotation/ImageAnnotationTool";
import Login from "./Pages/Login/Login";
import ReportList from "./Pages/ReportList/ReportList";
import ImageTool from "./Components/ImageAnnotation/ImageTool";
import ReportWithOutList from "./Pages/ReportList/ReportWithoutFlag";
import ViewReportImages from "./Pages/ViewReportImages/ViewReportImages";
import ShowReportImages from "./Pages/ShowReportImages/ShowReportImages";
import AnnotationTool from "./Pages/AnnotationTool/AnnotationTool";
import AnnotatedReport from "./Pages/ReportList/AnnotatedReport";
import ReviewerFeedback from "./Pages/ReviewerFeedback/ReviewerFeedback";
import ReportImageInDialogBox from "./Components/UI/Dialog/ReportImageInDialogBox";
import ThirdPartyReportsList from "./Pages/ReportList/ThirdPartyReportsList";
import ReviewerReviewedReports from "./Pages/ReviewedReports/ReviewedReports";

import PoorAlignmentReports from "./Pages/PoorAlignmentReports/PoorAlignmentReports.page";
import ReviewedReports from "./Pages/ReviewedReports/ReviewedReports";
import ToolUsers from "./Pages/ToolUsers/ToolUsers";
import { AnnotationWithCanvas } from "./Pages/AnnotationTool/NewAnnotationTool/AnnotationWithCanvas";
import FolderImages from "./Pages/FolderImages/FolderImages.page";
import ListImages from "./Pages/FolderImages/Components/ListImages.component";
import ListAnnotatedImages from "./Pages/FolderImages/ListAnnotatedImages.page";
import AnnotatorManagement from "./Pages/AnnotatorManagement/AnnotatorManagement.page";
import { getAssignedTagsAPI } from "./Services/annotator.service";
import { userDataAction } from "./store/userDataSlice";
import ListImagesForAnnotation from "./Pages/FolderImages/Components/ListImagesForAnnotation";
import TestingPage from "./Pages/ReportList/Components/testingTableList.page";

import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import { Canvas } from "./Components/CanvasAnnotation/CanvasAnnotation";

export function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storeValue = useSelector(store => store.userData);
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    if (user && !storeValue.userData?._id) {
      getTagsAndPermission();
    }
  }, []);

  const getTagsAndPermission = async () => {
    try {
        const tagsAndPermissionData = await getAssignedTagsAPI();
        if (tagsAndPermissionData?.data?.data) {
          dispatch(userDataAction.updateTagsAndPermission(tagsAndPermissionData?.data?.data));
        }
    } catch (error) {
        localStorage.clear();
        navigate('/');
    }
};

  // const navigate = useNavigate();
  // const onIdle = () => {
  //   console.log('fires after 60 minutes');
  //   //insert any custom logout logic here
  //   localStorage.clear();
  //   navigate('/');
  // }
  // const { getRemainingTime } = useIdleTimer({
  //   onIdle,
  //   timeout: 60 * 60 * 1000, //60 minute idle timeout
  // })
  return (
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route exact path="/tool" element={<ImageAnnotationTool />} />
      {/* <Route exact path="/flag-report-list" element={<ReportList />} /> */}
      <Route exact path="/report-list" element={<ReportWithOutList />} />
      <Route exact path="/image-tool" element={<ImageTool />} />
      <Route path="/image-annotation-tool" element={<ReportImageInDialogBox />} />
      <Route path="/show-report-images" element={<ShowReportImages />} />
      <Route path="/annotation-tool" element={<AnnotationTool />} />
      <Route path="/annotated-report" element={<AnnotatedReport />} />
      <Route path="/reviewer-feedback" element={<ReviewerFeedback />} />
      <Route path="/third-party-reports" element={<ThirdPartyReportsList />} />
      <Route path="/poor-alignment-reports" element={<PoorAlignmentReports />} />
      <Route path="/reviewed-reports" element={<ReviewedReports />} />
      <Route path="/annotation-overview" element={<AnnotationWithCanvas />}></Route>
      <Route path="/tool-users" element={<ToolUsers />}></Route>
      <Route path="/folders" element={<FolderImages />}></Route>
      <Route path="/list-images" element={<ListImages />}></Route>
      <Route path="/list-annotated-images" element={<ListAnnotatedImages />}></Route>
      <Route path="/annotator-management" element={<AnnotatorManagement />} ></Route>
      <Route path="/list-images-for-annotation" element={<ListImagesForAnnotation />} ></Route>
      {/* <Route path="/view-report-images/:parentFolder?/:subFolder1?/:subFolder2?/:imageName?" element={<ViewReportImages />} /> */}

      {/* Testing routes */}
      <Route path="/testing-page" element={<TestingPage />} ></Route>
      <Route path="/canvas-annotation" element={<Canvas />} />
    </Routes>

  );
}
