import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    contrast: 1,
    brightness: 0
}

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        contrast(state, action) {
            state.contrast = action.payload;
        },
        brightness(state, action) {
            state.brightness = action.payload;
        }
    }
});

export const uiActions = uiSlice.actions;
export default uiSlice.reducer;