import React, { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress, Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';


import CloseIconButton from '../Button/CloseIconButton';
import ToolHeader from '../Header/ToolHeader';
import PositionedSnackbar from '../Snackbar/Snackbar';
import { Canvas } from '../../CanvasAnnotation/CanvasAnnotation';
import BasicCard from '../Cards/BasicCard';
import { getAssignedTagsAPI } from '../../../Services/annotator.service';
import { GetBucketFile, StoreJsonFile } from '../../../Services/reportService';
import { getOriginalImageWidthAndHeight, getTagName, removeExtension, TAGS_COLOR_CODE, TAGS_SHORT_NAME } from './Utility';
import { API_RESPONSE, IMAGE_TAGS, USER_TYPE } from '../../../constants/common.constant';
import { createRectangle, reset, useShapes } from '../../CanvasAnnotation/state';
import { getReportType } from './reportDialog.helper';
import DetectionListForm from '../Forms/DetectionListForm';
import LegendsList from '../List/LegendsList';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

let detectionListFormData = {
    poor_alignment: { upper_teeth: [], lower_teeth: [] },
    dark_teeth: { upper_teeth: 'normal', lower_teeth: 'normal' }
};

export default function NewAnnotationDialog(props) {

    const navigate = useNavigate();
    const location = useLocation();
    const shapes = useShapes((state) => Object.entries(state.shapes));
    const getImagesFromStore = useSelector(state => state.reportImage);
    const subFolder = props.filePath ? props.filePath : props.subFolderName;
    const imagePath = `${props.bucketName}/${subFolder}/${props?.imageData?.image}`;
    const fileFolderPath = `${props.bucketName}/${subFolder}`;

    const [open, setOpen] = useState(props.isDialogBoxOpen);
    const [locationData, setLocationData] = useState(location?.state?.data);
    const [activeImage, setActiveImage] = useState(getImagesFromStore[imagePath]);
    const [snackbarData, setSnackbarData] = useState(null);
    const [activeTool, setActiveTool] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [imageName, setImageName] = useState(props?.imageData?.image);
    const [isUseableImage, setIsUseableImage] = useState(true);
    const [feedbackValue, setFeedbackValue] = useState();
    const [missingTeethData, setMissingTeethData] = useState([]);
    const [permissionData, setPermissionsData] = useState();
    const [isLegendFilterApplied, setIsLegendFilterApplied] = useState(false);
    const [tagsListArray, setTagsListArray] = useState([]);
    const [legendFilters, setLegendFilters] = useState([]);
    const [shapesData, setShapesData]  = useState([]);
    const [accessTags, setAccessTags]  = useState([]);
    const [isRectangleShapeSelected, setIsRectangleShapeSelected] = useState(false);
    const [showImageWithoutTags, setShowImageWithoutTags] = useState(false);
    const [isShowLabel, setIsShowLabel] = useState(true);

    const user = JSON.parse(localStorage.getItem('user'));
    const isSuperAnnotator = user?.user_type === USER_TYPE.SUPER_ANNOTATOR;

    // refs
    const canvasContainerRef = useRef();
    const dialogContainer = document.getElementsByClassName('MuiDialog-container')[0]
    const dialogHeight = dialogContainer?.offsetHeight - 320;
    const dialogWidth = dialogContainer?.offsetWidth / 2;

    useEffect(() => {
        getTagsList();
        getImageCoordinates();
    }, []);

    // useEffect(() => {
    //     setShapesData(shapes)
    // }, [shapes.length])

    const handleClose = () => {
        props.handleCloseDialogBox(false);
        setOpen(false);
        reset();
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    }

    const getTagsList = async () => {
        try {
            const tagsListData = await getAssignedTagsAPI(`${props.subFolderName}/${imageName}`);
            if (tagsListData?.data?.data) {
                const tagsAndAccessData = tagsListData.data.data;
                // setTagsList(tagsAndAccessData);
                setTagsListArray(tagsAndAccessData.access_tags);
                setAccessTags(tagsAndAccessData.access_tags)
                setPermissionsData({
                    isImageUseableAccess: tagsAndAccessData.is_image_useable_access,
                    isDarkTeethAccess: tagsAndAccessData.is_dark_teeth_access,
                    isMissingTeethAccess: tagsAndAccessData.is_missing_teeth_access,
                    isPoorAlignmentAccess: tagsAndAccessData.is_poor_alignment_access
                })
            }
        } catch (error) {
            localStorage.clear();
            navigate('/');
        }
    };

    const getImageCoordinates = async () => {
        try {
            const isReviewer = user.user_type === USER_TYPE.REVIEWER || isSuperAnnotator ? true : false;
            if (isReviewer) {
                const trimSubFolder = `${props.subFolderName.split('/').join(':')}`.trim();
                const getFileName = `${trimSubFolder}:${removeExtension(imageName)}-reviewer.json`
                const reviewerJsonData = await GetBucketFile({ fileName: getFileName, fileFolder: `${props.JSONBucketName}/${props.JSONFolderName}` });
                const tagsData = reviewerJsonData.data.data;
            } else {
                const annotatorJsonData = await GetBucketFile({
                    fileName: `${removeExtension(imageName)}.json`,
                    fileFolder: `${props.JSONBucketName}/${props.JSONFolderName}`
                });
                const tagsData = annotatorJsonData.data.data;
                // console.log("tagsData annotator", tagsData);
                if (tagsData !== API_RESPONSE.NO_DATA) {
                    for (let key in tagsData) {
                        if (Array.isArray(tagsData[key]) && key !== IMAGE_TAGS.MISSING_TEETH) {
                            const getCoordinatesData = imageCoordinatesCalculation(key, tagsData);
                            getCoordinatesData.forEach((item, index) => {
                                const { width, height, x, y, name, color, tagType } = item;
                                createRectangle({ width, height, x, y }, { name, color, tagType });
                            })
                        } else {
                            if (Array.isArray(tagsData[key]) && props?.isAligner) {
                                const getCoordinatesData = imageCoordinatesCalculation(key, tagsData);
                            }
                        }
                    }
                    storeMissingTeethToLocalStorage(tagsData);
                } else {
                   await getTagsFromTxtFile();
                }
            }
        } catch (error) {

        }
    }

    async function getTagsFromTxtFile() {
        let txtFileName;
        if (props.isAnnotatedReports) {
            txtFileName = `${removeExtension(imageName)}.txt`;
        } else {
            const splitFolder = props.subFolderName.split('/');
            const txtSubFolder = splitFolder.length > 0 ? splitFolder[splitFolder.length - 1] : props.subFolderName;
            txtFileName = `${txtSubFolder}_${removeExtension(imageName)}.txt`;
            // txtFileName = `${removeExtension(imageName)}.txt`;
        }
        const txtData = await GetBucketFile({ fileName: txtFileName, fileFolder: `${props.bucketName}/${props.txtFolderName}` });
        if (txtData) {
            tagsCalculation(txtData);
        }
    }

    function tagsCalculation(tags) {
        const imgWidth = canvasContainerRef?.current?.offsetWidth;
        const imgHeight = canvasContainerRef?.current?.offsetHeight;

        const tagsData = tags.data.data.split('\n');
        tagsData.forEach((data, index) => {
            const splitCoordinates = data.split(' ').slice(1, 5);
            if (splitCoordinates.length > 1) {
                const { x, y, w, h } = {
                    x: Number(splitCoordinates[0]),
                    y: Number(splitCoordinates[1]),
                    w: Number(splitCoordinates[2]),
                    h: Number(splitCoordinates[3])
                };
                let x_min = (x - w / 2) * imgWidth;
                let x_max = (x + w / 2) * imgWidth;
                let y_min = (y - h / 2) * imgHeight;
                let y_max = (y + h / 2) * imgHeight;

                const tagValue = Number(data.split(' ')[0]);
                const { tagName, colorCode } = GetTagNameAndColorCode(tagValue);

                createRectangle(
                    { width: x_max - x_min, height:  y_max - y_min, x: x_min, y: y_min },
                    { name: tagName, color: colorCode, tagType: TAGS_SHORT_NAME[tagName]}
                );
                
            }
        });
        // saveImageTags(imageTags, true);
    }

    const GetTagNameAndColorCode = (value) => {
        switch (value) {
            case 0:
                return {
                    tagName: 'C1',
                    colorCode: TAGS_COLOR_CODE['C1']
                }
            case 1:
                return {
                    tagName: 'G',
                    colorCode: TAGS_COLOR_CODE['G']
                }
            case 2:
                return {
                    tagName: 'GR',
                    colorCode: TAGS_COLOR_CODE['GR']
                }
            case 3:
                return {
                    tagName: 'GS',
                    colorCode: TAGS_COLOR_CODE['GS']
                }
            case 4:
                return {
                    tagName: 'T',
                    colorCode: TAGS_COLOR_CODE['T']
                }
            default:
                return {};

        }
    }

    const imageCoordinatesCalculation = (key, tagsData) => {
        const { width, height } = getOriginalImageWidthAndHeight(activeImage);
        const imgNaturalWidth = width;
        const imgNaturalHeight = height;
        const renderImageWidth = canvasContainerRef.current.offsetWidth;
        const renderImageHeight = canvasContainerRef.current.offsetHeight;
        const coordinatesData = [];
        tagsData[key].forEach((item) => {
            let { x, y, w, h } = item;
            x = (x * renderImageWidth) / imgNaturalWidth;
            y = (y * renderImageHeight) / imgNaturalHeight;
            w = (w * renderImageWidth) / imgNaturalWidth;
            h = (h * renderImageHeight) / imgNaturalHeight;
            const shortName = getTagName(key, item);
            const color = TAGS_COLOR_CODE[shortName] || '#5fbadd';
            const tagType = TAGS_SHORT_NAME[shortName]
            coordinatesData.push({ x, y, width: w, height: h, name: shortName, tagType, color });
        });
        return coordinatesData;
    }

    const getJSONFIleName = () => {
        if (props.isThirdPartyReports) {
            const trimSpace = `${props.subFolderName.split('/').join(':')}`.trim();
            return `${trimSpace}:${removeExtension(imageName)}-reviewer.json`;
        }
        if (props.isGapsInFrontTeethReports || isSuperAnnotator) {
            const subFolder = props.subFolderName.split('/').join(':');
            return `${subFolder}:${removeExtension(imageName)}-reviewer.json`;
        }
        switch (user?.user_type) {
            case USER_TYPE.ANNOTATOR:
                return `${removeExtension(imageName)}.json`;
            case USER_TYPE.REVIEWER:
                return `${removeExtension(imageName)}-reviewer.json`;
            case USER_TYPE.ALIGNER:
                return `${removeExtension(imageName)}-aligner.json`;
            default:
                return;
        }
    }

    const getInitialJSONData = () => {
        const sampleJSON = {
            image_name: !locationData?.isThirdPartyReports ? `${props?.subFolderName}/${imageName}` : `${props?.subFolderName}/${props?.imageData?.image}`,
            image_type: !locationData?.isThirdPartyReports ? props?.imageData?.imageType : '',
            image_useable: { is_image_useable: isUseableImage, reason: '' },
        }
        if (props.isAligner) {
            return {
                ...sampleJSON,
                aligner_tags: [],
            }
        }
        return {
            ...sampleJSON,
            cavity: [],
            tartar: [],
            gum_recession: [],
            gum_swelling: [],
            broken_teeth: [],
            enamel: [],
            gaps: [],
            missing_teeth: [],
        }
    }

    function saveImageTags(imageTags = [], isInitialSave = false) {
        // Get JSON file name
        const jsonFileName = getJSONFIleName();
        // Get JSON initial data
        const jsonData = getInitialJSONData();

        imageTags = shapes;
        const formattedJsonData = formatJsonData(jsonData, imageTags);
        if (formattedJsonData && user?.user_type !== 'admin') {
            // console.log("formattedJsonData yes", formattedJsonData, jsonFileName);
            const dataObj = {
                data: formattedJsonData,
                parentFolder: props.JSONBucketName,
                subFolder: props.JSONFolderName,
                jsonFileName,
                feedback: feedbackValue,
                reportId: props.reportId,
                reportType: getReportType(props)
            }
            // setIsLoading(true);
            StoreJsonFile(dataObj)
                .then((response) => {
                    if (!isInitialSave) {
                        // saveImageAnnotation(formattedJsonData);
                        setSnackbarData({ message: 'Successfully stored data', severity: 'success' });
                        // detectionListFormData = {};
                        setTimeout(() => {
                            props.handleCloseDialogBox(true);
                            setIsLoading(false);
                            setOpen(false);
                        }, 1000);
                    }
                })
                .catch((err) => {
                    localStorage.clear();
                    navigate('/');
                })

        }
        return;
    }

    const uniqueArray = (arr) => {
        arr.filter((value, index, self) => 
            index === self.findIndex((t) => (
                // Compare based on `x`, `y`, `w`, `h`
              t.x === value.x && t.y === value.y && t.w === value.w && t.h === value.h
            ))
          );
    }

    function formatJsonData(jsonData, imageTags) {
        const { width, height } = getOriginalImageWidthAndHeight(activeImage);
        const imgNaturalWidth = width;
        const imgNaturalHeight = height;
        const renderImageWidth = canvasContainerRef.current.offsetWidth;
        const renderImageHeight = canvasContainerRef.current.offsetHeight;
        // console.log("imageTags", imageTags);


        imageTags.forEach((item, index) => {
            let { x, y, width: w, height: h } = item[1];
            item = item[1];
            // console.log((x*480)/752, (y*328)/512, (w*480)/752, (h*328)/512);
            x = ((x * imgNaturalWidth) / renderImageWidth).toFixed(6);
            y = ((y * imgNaturalHeight) / renderImageHeight).toFixed(6);
            w = ((w * imgNaturalWidth) / renderImageWidth).toFixed(6);
            h = ((h * imgNaturalHeight) / renderImageHeight).toFixed(6);

            let teethData = [];
            switch (item.name) {
                case 'C1':
                case 'C2':
                case 'C3':
                case 'C':
                    teethData = jsonData.cavity?.length > 0 ? [...jsonData.cavity] : [];
                    teethData.push({ x, y, w, h, type: item.name });
                    jsonData.cavity = [...new Map(teethData.map(item => [`${item.x}-${item.y}-${item.w}-${item.h}`, item])).values()];
                    break;
                case 'E':
                    teethData = jsonData.enamel?.length > 0 ? [...jsonData.enamel] : [];
                    teethData.push({ x, y, w, h });
                    jsonData.enamel = [...new Map(teethData.map(item => [`${item.x}-${item.y}-${item.w}-${item.h}`, item])).values()];
                    break;
                case 'BT':
                    teethData = jsonData.broken_teeth?.length > 0 ? [...jsonData.broken_teeth] : [];
                    teethData.push({ x, y, w, h });
                    jsonData.broken_teeth = [...new Map(teethData.map(item => [`${item.x}-${item.y}-${item.w}-${item.h}`, item])).values()];
                    break;
                case 'T':
                    teethData = jsonData.tartar?.length > 0 ? [...jsonData.tartar] : [];
                    teethData.push({ x, y, w, h })
                    jsonData.tartar = [...new Map(teethData.map(item => [`${item.x}-${item.y}-${item.w}-${item.h}`, item])).values()];
                    break;
                case 'GR':
                    teethData = jsonData.gum_recession?.length > 0 ? [...jsonData.gum_recession] : [];
                    teethData.push({ x, y, w, h });
                    jsonData.gum_recession = [...new Map(teethData.map(item => [`${item.x}-${item.y}-${item.w}-${item.h}`, item])).values()];
                    break;
                case 'GS':
                    teethData = jsonData.gum_swelling?.length > 0 ? [...jsonData.gum_swelling] : [];
                    teethData.push({ x, y, w, h });
                    jsonData.gum_swelling = [...new Map(teethData.map(item => [`${item.x}-${item.y}-${item.w}-${item.h}`, item])).values()];
                    break;
                case 'G':
                    teethData = jsonData.gaps?.length > 0 ? [...jsonData.gaps] : [];
                    teethData.push({ x, y, w, h });
                    jsonData.gaps = [...new Map(teethData.map(item => [`${item.x}-${item.y}-${item.w}-${item.h}`, item])).values()];
                    break;
                default:
                    // This is for aligner
                    if (props.isAligner) {
                        teethData = jsonData.aligner_tags?.length > 0 ? [...jsonData.aligner_tags] : [];
                        teethData.push({ x, y, w, h });
                        jsonData.aligner_tags = teethData;
                    }
                    break;
            }
        });
        if (props.isAligner) {
            return {
                ...jsonData,
                image_useable: detectionListFormData?.image_useable ? detectionListFormData.image_useable : jsonData.image_useable,
                width: imgNaturalWidth,
                height: imgNaturalHeight
            }
        }
        return {
            ...detectionListFormData,
            ...jsonData,
            image_useable: detectionListFormData?.image_useable ? detectionListFormData.image_useable : jsonData.image_useable,
            missing_teeth: detectionListFormData?.missing_teeth ? detectionListFormData.missing_teeth : jsonData.missing_teeth,
            width: imgNaturalWidth,
            height: imgNaturalHeight
        };
    }

    function storeMissingTeethToLocalStorage(tagsData) {
        // Storing missing teeth data to a local storage
        const getTeethFromLocalStorage = JSON.parse(localStorage.getItem('teeth'));
        const getMissingTeeth = tagsData?.missing_teeth?.length > 0 ? tagsData.missing_teeth : [];
        let teethData;
        if (getTeethFromLocalStorage) {
            teethData = [...getTeethFromLocalStorage, ...getMissingTeeth]
        } else {
            teethData = [...props.missingTeeth, ...getMissingTeeth];
        }
        setMissingTeethData([...new Set(teethData)]);
    }

    const handleDetectionFormValues = (formValues) => {
        switch (formValues?.name) {
            case IMAGE_TAGS.IMAGE_USEABLE:
                detectionListFormData.image_useable = formValues.value
                break;
            case IMAGE_TAGS.MISSING_TEETH:
                detectionListFormData.missing_teeth = formValues.value;
                setMissingTeethData([...formValues.value]);
                break;
            case IMAGE_TAGS.POOR_ALIGNMENT:
                detectionListFormData.poor_alignment = formValues.value;
                break;
            case IMAGE_TAGS.DARK_TEETH:
                detectionListFormData.dark_teeth = formValues.value;
                break;
            default:
                break;
        }
    }

    const handleFeedbackValue = (value) => {
        setFeedbackValue(oldState => {
            return value
        });
    }

    const handleLegendsListChanges = (listData) => {
        const tagsArray = [...accessTags];
        let filteredtags = [];
        if (listData?.status) {
            tagsArray.push(listData.type.toLowerCase())
            filteredtags = tagsArray;
        } else {
            filteredtags = tagsArray.filter((item) => item.toLowerCase() !== listData.type.toLowerCase());
        }
        setAccessTags(filteredtags);
    }

    return (
        <Dialog
            fullScreen
            open={open}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
        >
            <PositionedSnackbar
                isOpenSnackbar={snackbarData?.message ? true : false}
                severity={snackbarData?.severity}
                message={snackbarData?.message}
                onCloseSnackBar={handleSnackbarClose}
            />
            <DialogTitle>
                <div className='text-end'>
                    <CloseIconButton handleClose={handleClose} />
                </div>
                {/* Tool header */}
                {
                    isLoading ? <div className='text-center'>Loading data...</div> :
                        <ToolHeader
                            drawRegion={() => {}}
                            // clearRect={clearRect}
                            showImageWithoutTags={showImageWithoutTags}
                            handleImageWithoutTags={() => {
                                setActiveTool('box');
                                setShowImageWithoutTags(prevState => !prevState)
                                setIsRectangleShapeSelected(false)
                                setIsShowLabel(true)
                            }}
                            isShowLabel={isShowLabel}
                            handleLabel={() => {
                                setActiveTool('label');
                                setIsShowLabel(prevState => !prevState)
                                setIsRectangleShapeSelected(false)
                                setShowImageWithoutTags(false);
                            }}
                            isAligner={props.isAligner}
                            activeTool={activeTool}
                            // currentAligner={alignerUser ? alignerUser?.current_aligner : ""}
                            checkedAligner={locationData?.aligner_number}
                            handleShapeSelected={() => {
                                setActiveTool('rectangle')
                                setIsRectangleShapeSelected(prevState => !prevState)
                                setShowImageWithoutTags(false);
                                setIsShowLabel(true)
                            }}
                            isRectangleShapeSelected={isRectangleShapeSelected}
                        />
                }
            </DialogTitle>
            <DialogContent>
                <div className='row position-relative'>
                    {/* New annotation */}
                    <div className='col-6'>

                        <div ref={canvasContainerRef}>
                            <Canvas
                                reportImage={activeImage}
                                reportImageName={props?.imageData?.image}
                                subFolderName={props.subFolderName}
                                shapesData={shapesData}
                                accessTags={accessTags}
                                isRectangleShapeSelected={isRectangleShapeSelected}
                                showImageWithoutTags={showImageWithoutTags}
                                isShowLabel={isShowLabel}
                            />
                        </div>

                        <div className='row'>
                            <div className='col-12'>
                                <LegendsList
                                    handleLegendsListChanges={handleLegendsListChanges}
                                    isLegendFilterApplied={isLegendFilterApplied}
                                    tagsList={tagsListArray} />
                            </div>
                        </div>

                    </div>
                    <div className='col-6'>
                        {isLoading && props.isAligner && <Skeleton variant="rectangular" with="100%" height={500} />}
                        {/* Detection list form */}
                        <DetectionListForm
                            imageType={props?.imageData?.imageType}
                            handleDetectionFormValues={handleDetectionFormValues}
                            detectionListFormData={detectionListFormData}
                            isReviewer={user?.user_type === 'reviewer' ? true : false}
                            handleFeedbackValue={handleFeedbackValue}
                            missingTeethData={missingTeethData}
                            appDetails={locationData?.app}
                            isAligner={props?.isAligner}
                            permissionData={permissionData}
                        />
                    </div>
                </div>
                <hr />
            </DialogContent>
            <DialogActions className='mb-3'>
                <Button variant="contained" onClick={handleClose}>Cancel</Button>
                <Button variant="contained" onClick={saveImageTags}>
                    {isLoading ? <CircularProgress className='text-white' style={{ width: '25px', height: '25px' }} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}