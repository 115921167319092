import React, { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SettingsIcon from '@mui/icons-material/Settings';
import { useDispatch } from 'react-redux';
import { uiActions } from '../../../store/uiSlice';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default function SettingMenu() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [contrast, setContrast] = useState(1)
    const [brightness, setBrightness] = useState(0);
    const dispatch = useDispatch();
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleContrast = (event) => {
        dispatch(uiActions.contrast(event.target.value))
        setContrast(event.target.value)
    };

    const handleBrightness = (event) => {
        dispatch(uiActions.brightness(event.target.value))
        setBrightness(event.target.value)
    }

    const handleReset = () => {
        dispatch(uiActions.contrast(1))
        setContrast(1)
        dispatch(uiActions.brightness(0))
        setBrightness(0)
    }

    return (
        <div>
            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                // variant="contained"
                disableElevation
                onClick={handleClick}
            // endIcon={<KeyboardArrowDownIcon className='text-white' />}
            >
                <SettingsIcon className='text-white' />
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <div className='p-3'>
                    {/* Adjust Contrast and Brightness */}
                    <div className="d-flex align-items-center">
                        <span className="me-4 font-size-14 fw-bold">Adjust Contrast : </span>
                        <input type="range"
                            min="0"
                            max="50"
                            step="5"
                            value={contrast}
                            onChange={handleContrast} />
                    </div>
                    <div className="d-flex align-items-center my-3">
                        <span className="me-3 font-size-14 fw-bold">Adjust Brightness : </span>
                        <input type="range"
                        min="-1"
                        max="1"
                        step="0.01"
                        value={brightness}
                        onChange={handleBrightness} />
                    </div>

                    <div className='text-end'>
                        <Button variant='contained' onClick={handleReset}>Reset</Button>
                    </div>

                </div>
            </StyledMenu>
        </div>
    );
}